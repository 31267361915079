import { useDispatch } from "react-redux";
import { Menu } from "@mantine/core";
import { useEffect, useState } from "react";

const Navbar = () => {
    const dispatch = useDispatch()

    const [hasScrolled, setHasScrolled] = useState(false);

    useEffect(() => {
        function handleScroll() {
            const threshold =
                0;
            ;
            if (window.pageYOffset > threshold) {
                setHasScrolled(true);

            } else {
                setHasScrolled(false);

            }
        }

        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    return (
        <>

            <nav className={
                hasScrolled ? 'nav-bar scrolled' : 'nav-bar'
            }>
                <img onClick={
                    () => {
                        document.getElementById('hero').scrollIntoView({ behavior: 'smooth', block: 'center' })
                    }
                } src='/assets/logo.webp'></img>
                <ul>
                    <li onClick={
                        () => {
                            document.getElementById('hero').scrollIntoView({ behavior: 'smooth', block: 'center' })

                        }
                    }><a >Home</a></li>

                    <li><a onClick={
                        () => {
                            document.getElementById('contact').scrollIntoView({ behavior: 'smooth', block: 'center' })
                        }
                    }>Contact Us</a></li>
                    <li><a href="https://buy.stripe.com/eVadUMelt0lU0sU6oo" target="_blank">Pay Online</a></li>


                </ul>

            </nav>
        </>
    );
}

export default Navbar;