import { createAsyncThunk } from '@reduxjs/toolkit'
import { isLogin, isNotLogin, resetAuth, userInformation } from '../store/schemas/authSlice'
import { notifications } from '@mantine/notifications';

const server = process.env.REACT_APP_DOMAIN

export const getUser = createAsyncThunk(
    'auth/getUser',
    async (data, thunkApi) => {
        try {
            const response = await fetch(`${server}?auth=true&model=user&path=/getUser`, {
                method:'POST',
                credentials: 'include',
                headers: {"Content-Type": "application/json"},
                body: JSON.stringify({})               
            })
            const results = await response.json()
            if("error" in results){
                throw results
            }
            thunkApi.dispatch(isLogin())
            thunkApi.dispatch(userInformation(results.message))
            return results
        } catch (error) {
            
            return error
        }
    }
  )

  export const logout = createAsyncThunk(
    'auth/logout',
    async (data, thunkApi) => {
        try {
            const response = await fetch(`${server}?auth=false&model=auth&path=/signOut`, {
                method:'POST',
                credentials: 'include',
                headers: {"Content-Type": "application/json"},
                body: JSON.stringify({})               
            })
            const results = await response.json()            
            if("error" in results){
                throw results
            }
            thunkApi.dispatch(resetAuth())
            localStorage.clear()
            window.location.href =  window.location.origin + '/app'           

            return results
        } catch (error) {
            notifications.show({title: error.error,message: error.message})
            return error
        }
    }
  )
