import { useDispatch } from "react-redux";
import { Card, Menu } from "@mantine/core";
import { useEffect, useState } from "react";
import { Carousel } from '@mantine/carousel';
import '@mantine/core/styles.css';
import '@mantine/carousel/styles.css';
import { useNavigate } from "../../../node_modules/react-router-dom/dist/index";


const Specialties = () => {
    const dispatch = useDispatch()
    const navigate = useNavigate()

    const servicesSchema = [
        /*   {
              id: 1,
              icon: "/assets/icons/btc.json",
              text: <><h3 style={{ color: "#FFAF22" }}>Software Services</h3>
                  <p>
                      Software Developement, Consultancy, and Integration
                  </p></>,
              route: '/blockchain-software',
              style: 'large-block'
          }, */
        {
            id: 8,
            icon: "/assets/icons/globe.json",
            text: <p>We Build Powerful and Custom <span style={{ color: "#4AB3FC" }}>Websites</span>, Web <span style={{ color: "#4AB3FC" }}>Applications</span> and Software Solutions</p>,
            route: '/website-webapps',
            style: 'large-block'
        },
        {
            id: 2,
            icon: "/assets/icons/mobile.json",
            text: <p>iOS and Android <span style={{ color: "#F14B00" }}>Mobile App</span> Developement</p>,
            route: '/mobile',
            style: 'small-block'
        },
        {
            id: 3,
            icon: "/assets/icons/live.json",
            text: <p>
                Real-Time <span style={{ color: "#FFAF22" }}>Web Apps</span> and <span style={{ color: "#FFAF22" }}>Software</span> Solutions
            </p>,
            route: '/real-time',
            style: 'small-block'
        },
        {
            id: 4,
            icon: "/assets/icons/chain.json",
            text: <p>
                <span style={{ color: "#0da888" }}>Blockchain</span> and <span style={{ color: "#0da888" }}>Web3</span> Software Solutions
            </p>,
            route: '/blockchain',
            style: 'small-block'
        },
        {
            id: 5,
            icon: "/assets/icons/ai.json",
            text: <p>
                We Build <span style={{ color: "#49b3fc" }}>AI</span> and <span style={{ color: "#49b3fc" }}>Machine Learning</span> Software Solutions
            </p>,
            route: '/ai-software',
            style: 'small-block'
        },
    ]

    return (
        <>

            <section id="specialties" className='card-container'>
                <div className='card-center-wrapper'>
                    <h1>Our Specialties</h1>
                    <Carousel align={
                        typeof window != "undefined" ? window.innerWidth < 768 ? 'center' : 'start' : 'start'
                    } height={350} slideSize="150px" slideGap={0} controlsOffset="md" withIndicators slidesToScroll={{ base: 3, sm: 1, md: 1 }}>


                        {
                            servicesSchema.map((service) => {
                                return (
                                    <Carousel.Slide >
                                        <button onClick={
                                            () => {
                                                navigate(service.route)
                                            }
                                        } id={'spec' + service.id} className={`spec-block ${service.style}`}>
                                            <lord-icon style={{ marginTop: "-15px" }} stroke="80" target={'#spec' + service.id} trigger="hover" src={service.icon}></lord-icon>
                                            <div className='spec-text'>
                                                {service.text}
                                            </div>
                                        </button>
                                    </Carousel.Slide>
                                )
                            }
                            )
                        }



                    </Carousel>
                    {/*   <p>We specialize in even more. Mobile App Development, Software Development Consultancy, Multi-Media Services, Augmented reality and Spatial Computing, iOS and Android App Development, Financial Services Software, Renting and Home Owner Software. Feel free to reach out us at our contact form.</p> */}
                </div>
            </section>
        </>
    );
}

export default Specialties;