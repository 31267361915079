import React, { useRef, useEffect, useState } from 'react';
import { Carousel } from '@mantine/carousel';


import Navbar from './components/navbar';
import Specialties from './specialties/specialties';
import Contact from './contact/contact';
import S_Modal from './specialties/s_modal';
import { Outlet } from 'react-router-dom';


const Homepage = () => {

  const [hasScrolled, setHasScrolled] = useState(false);


  return (
    <>
      <Outlet />
      <div className='homepage-container'>
        <header >
          <Navbar />
        </header>

        <div className='homepage-content-container'>
          <section id='hero' className='hero-container'>
            <div className='hero-center-wrapper'>
              <div className='hero-text-container'>
                <span>Software Development Agency</span>
                <h1>Let's Create Something Great Together</h1>
              </div>
              <div className='callto-action'>
                <a href='#contact'>
                  <button id='btn1'><span>Get Started</span>
                    <img src='/assets/arrow.png'></img>
                  </button>
                </a>


              </div>
            </div>
          </section>

          <Specialties />

          <Contact />


          <footer>
            <img src='/assets/logo.webp'></img>
            <p>Copyright © 2024 Eldrbreak. All Rights Reserves </p>

          </footer>
        </div>


      </div>

    </>
  );

}

export default Homepage;