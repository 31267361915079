import { useDispatch } from "react-redux";
import { Menu } from "@mantine/core";
import { useEffect, useState } from "react";
import { useNavigate } from "../../../node_modules/react-router-dom/dist/index";

const Contact = () => {
    const dispatch = useDispatch()
    const navigate = useNavigate()



    return (
        <>
            <section className='contact-container' id='contact'>
                <div className='contact-center-wrapper'>
                    <div className='contact-text'>
                        <h1>Reach Out and Get Started</h1>
                        <p>We want to here from you, whether it’s a massive project or you just want to have a chat about tech. Let’s chat!</p>
                        <div className='callto-action'>
                            <a>
                                <button onClick={
                                    () => {
                                        navigate('/contact')
                                    }

                                } style={{ backgroundColor: "#F14B00", color: "var(--main-color)" }} id='btn1'><span style={{ color: 'black' }}>Contact Us</span> <img src='/assets/arrowblack.png'></img></button>
                            </a>
                        </div>
                    </div>

                    <div className='contact-card' >
                        <img src='/assets/email.png'></img>
                        <div className='spec-text'>
                            <p><span style={{ color: "#F14B00" }}>Email</span> us Directly & Get A Response<span style={{ color: "#F14B00" }}> ASAP</span></p>
                        </div>
                        <div className='contact-card-footer'>
                            <h3>Available Weekday <span>9am - 4pm</span> CST</h3>
                        </div>
                    </div>
                </div>
            </section>

        </>
    );
}

export default Contact;