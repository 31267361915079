import { useDispatch } from "react-redux";
import { Outlet } from "react-router-dom";
import { logout } from "../httpcalls/authThunk";
import { MantineProvider, createTheme } from '@mantine/core';


const MainDirectory = () => {
  const dispatch = useDispatch()

  return (
    <>
      <MantineProvider>
        <Outlet />
      </MantineProvider>

    </>
  );
}

export default MainDirectory;