import { useDispatch } from "react-redux";
import { useNavigate } from "../../../node_modules/react-router-dom/dist/index";
import { useEffect } from "react";


const S_Modal = (props) => {
    const dispatch = useDispatch()
    const navigate = useNavigate()


    return (
        <>
            <div id="modal" className="modal-container">
                <button className="large-close" onClick={
                    () => {
                        navigate(-1)
                    }
                }></button>
             
                    <div className="modal-wrapper vivify fadeInBottom duration-300">
                        <div className="modal-navbar">
                            <button onClick={
                                () => {
                                    navigate(-1)
                                }
                            } className="modal-close"><i className="material-icons">close</i></button>
                            <lord-icon stroke="80" target="#modal" trigger="hover" src={props.icon}></lord-icon>
                        </div>

                        <div className="modal-content">
                            <div className="modal-title">
                                <h2 style={{ color: props.color }}>{props.subtitle}</h2>
                                <h1>{props.title}</h1>
                            </div>
                            <p>{props.text}</p>
                        </div>
                    </div>
            

            </div>
            <style>
                {
                    `
                    body {
                        overflow: hidden;
                    }
                    `
                }
            </style>
        </>
    );
}

export default S_Modal;