import React, { useState } from 'react';
import { Route, Routes } from 'react-router-dom';
// const LoadableLandingPage = loadable(() => import('./publicPages/lodable'))
import Homepage from './mainapp/homepage';
import MainDirectory from './mainapp/mainDirectory';
import { useEffect } from 'react';
import S_Modal from './mainapp/specialties/s_modal';
import C_Form from './mainapp/contact/c_form';

const App = () => {

    useEffect(() => {
        if (typeof window !== 'undefined') {
            // Dynamically import the module only on the client-side
            import('lord-icon-element').then((module) => {
                import('lottie-web').then((lottie) => {
                        
                     module.defineElement(lottie.loadAnimation)
                })
           
            });
        }
    }, [])

    return (
        <>
            <Routes>
                <Route path='/' element={<MainDirectory />}>
                    <Route path='/' element={<Homepage />}>
                        <Route path='/website-webapps' element={
                            <S_Modal
                                icon={"/assets/icons/globe.json"}
                                subtitle={"Websites & Web Applications"}
                                title={"Powerful & Custom Websites, Web Applications and Software Solutions"}
                                text={"Elevate your digital presence with tailor-made websites and web applications that capture attention and drive results. We blend design, technology, and strategy to create digital experiences that are not only visually stunning but also highly functional."}
                                color={"#4AB3FC"}
                            />}
                        />
                        <Route path='/mobile' element={
                            <S_Modal
                                icon={"/assets/icons/mobile.json"}
                                subtitle={"Mobile App Development"}
                                title={"Comprehensive iOS and Android App Development "}
                                text={"Connect with your customers wherever they are with custom mobile apps designed for both iOS and Android. Our apps are crafted to offer smooth, intuitive user experiences, ensuring your business is accessible at any time, right from your customers' pockets."}
                                color={'#F14B00'}
                            />}
                        />
                        <Route path='/real-time' element={
                            <S_Modal
                                icon={"/assets/icons/live.json"}
                                subtitle={"Real-Time Software Solutions"}
                                title={"Keep Your Users Engaged with Real-Time Features"}
                                text={"Keep your users engaged with real-time features that deliver instant updates and interactive experiences. Whether it's for live messaging, streaming content, or dynamic collaboration tools, our real-time solutions keep your users informed and connected."}
                                color={'#FFAF22'}
                            />}
                        />
                        <Route path='/blockchain' element={
                            <S_Modal
                                icon={"/assets/icons/chain.json"}
                                subtitle={"Blockchain & Web3 Solutions"}
                                title={"We Build Novel Blockchain and Web3 Apps & Games"}
                                text={"We build the future with our blockchain and Web3 application development services. From secure transactions to decentralized applications, we empower your business with transparent, efficient, and cutting-edge blockchain solutions."}
                                color={"#0da888"}

                            />}
                        />
                        <Route path='/ai-software' element={
                            <S_Modal
                                icon={"/assets/icons/ai.json"}
                                subtitle={"AI & Machine Learning"}
                                title={"AI & Machine Learning Software Solutions"}
                                text={"Leverage the power of AI and machine learning to drive business growth and innovation. Our AI and machine learning solutions are designed to help businesses automate processes, gain valuable insights, and deliver personalized experiences that drive customer engagement and loyalty."}
                                color={"#49b3fc"}
                            />}
                        />
                        <Route path='/contact' element={<C_Form />} />
                    </Route>
                </Route>
                <Route path='*' element={<h2>404</h2>} />
            </Routes>
        </>


    )
};

export default App;
