import { useDispatch } from "react-redux";
import { useNavigate } from "../../../node_modules/react-router-dom/dist/index";
import { useState } from "react";
import { notifications } from '@mantine/notifications';


const C_Form = (props) => {
    const navigate = useNavigate();

    const handleSubmit = async (e) => {
        e.preventDefault()
        fetch('/api/postForm', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                firstName: e.target.firstname.value,
                lastName: e.target.lastname.value,
                email: e.target.email.value,
                phone: e.target.number.value,
                message: e.target.message.value
            })
        }).catch((err) => { console.log(err) })

        navigate('/', { replace: true })
        notifications.show({ title: 'Message Sent', message: 'Your message has been sent, expect contact via email.', color: 'green', icon: <i style={{ color: 'white' }} className="material-icons">check_circle</i> })
    }

    return (
        <>
            <div id="modal" className="modal-container">
                <div className="modal-wrapper vivify fadeInBottom duration-300">
                    <div className="modal-navbar">
                        <button
                            aria-label="Close Modal"
                            onClick={() => { navigate(-1) }}
                            className="modal-close"
                        >
                            <i className="material-icons" aria-hidden="true">close</i>
                        </button>
                        <lord-icon
                            stroke="80"
                            target="#modal"
                            trigger="sequence"
                            src={'/assets/icons/email.json'}
                            role="img"
                            aria-label="Email Icon"
                        ></lord-icon>
                    </div>
                    <div className="modal-content">
                        <div className="modal-title">
                            <h2 style={{ color: "#F14B00" }}>Contact Us</h2>
                            <h1>Email Us</h1>
                        </div>
                        <form className="contact-us" onSubmit={(e) => { handleSubmit(e) }}>
                            <div className="form-group">

                                <div className="form-input">
                                    <label htmlFor="firstname">First Name</label>
                                    <input type="text" name="firstname" id="firstname" placeholder="First Name" />
                                </div>

                                <div className="form-input">
                                    <label htmlFor="lastname">Last Name</label>
                                    <input type="text" name="lastname" id="lastname" placeholder="Last Name" />
                                </div>
                            </div>
                            <div className="form-input">
                                <label htmlFor="email">Email</label>
                                <input type="email" name="email" id="email" placeholder="Email" />
                            </div>
                            <div className="form-input">
                                <label htmlFor="number">Phone Number</label>
                                <input type="number" name="number" id="number" placeholder="Phone Number" />
                            </div>
                            <div className="form-input">
                                <label htmlFor="message">Message</label>
                                <textarea name="message" id="message" placeholder="Message"></textarea>
                            </div>
                            <button className="submit-button" type="submit">Submit</button>
                        </form>
                    </div>

                </div>
            </div>
            <style>
                {`
                    body {
                        overflow: hidden;
                    }

                    .modal-navbar > lord-icon {
                        width: 545px;
                        height: 495px;
                        opacity: 0.2;
                        margin-right: -105px;
                        margin-top: -80px;
                    }

                    .modal-title {
                        margin-top: -344px;
                    }

                    .submit-button {
                        background-color: #F14B00;
                    }
                `}
            </style>
        </>
    );
}

export default C_Form;
